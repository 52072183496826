<template>
  <!-- 価格以外はPC用 -->
  <div class="c-serviceDetailPurchase">
    <div
      @mouseenter="isActiveOptions = true"
      @mouseleave="isActiveOptions = false"
    >
      <div
        class="c-phoneTalking"
        v-if="isPC && serviceDetail.serviceDetail.providerPhoneStatus === 'talking'"
      >
        <div class="c-phoneTalking_label">
          {{ $t('components.service_detail_purchase.pending') }}
        </div>
      </div>
      <div
        class="c-price"
        :class="{ 'c-price-spLoggedIn': isLoggedIn }"
      >
        <div class="c-price_label">
          {{ $t('price') }}
        </div>
        <!-- prettier-ignore -->
        <div class="c-price_content">
          <span v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone">
            <span class="c-price_price">{{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}</span>
            {{ $translate.exchangePriceUnit() }}/{{ $t('minute') }}
            <span
              v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
              class="c-price_taxLabel"
            >({{ $t('excluding_tax') }})</span>
          </span>
          <span v-else-if="serviceDetail.serviceDetail.unitTime">
            <span class="c-price_price">
              {{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}
            </span>
            {{ $translate.exchangePriceUnit() }}/{{ serviceDetail.serviceDetail.unitTime
            }}{{ $t('minute') }}
            <span
              v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
              class="c-price_taxLabel"
            >({{ $t('excluding_tax') }})</span>
          </span>
          <span v-else>
            <span class="c-price_price">{{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}</span>
            {{ $translate.exchangePriceUnit() }}
            <span
              v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
              class="c-price_taxLabel"
            >({{ $t('excluding_tax') }})</span>
          </span>
        </div>
      </div>
      <div
        class="c-point"
        v-if="isPC && !$translate.isTranslatableTarget()"
      >
        <span v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone">
          {{ serviceDetail.pointRatio }}{{ $t('components.service_detail_purchase.gain_point') }}
        </span>
        <span v-else>
          {{ $util.numberWithDelimiter(totalPoint) }}{{ $t('point') }} ({{
            serviceDetail.pointRatio
          }}％) {{ $t('components.service_detail_purchase.gain') }}
        </span>
      </div>
      <div
        class="c-coupon"
        v-if="isPC && couponName"
        data-translate
      >
        {{ couponName }}
        <span
          class="c-coupon_expired"
          v-if="couponRemainingDays < 1"
        >
          ({{ $t('components.service_detail_purchase.until_today') }})
        </span>
        <span
          class="c-coupon_expired"
          v-else
        >
          ({{ $t('components.service_detail_purchase.remaining') }}
          <span class="c-coupon_expiredTime">{{ couponRemainingDays }}</span>
          {{ $t('days') }})
        </span>
      </div>

      <ButtonPurchase
        v-if="isPC && (!isCustomizeRequired || isMyService)"
        :service-detail="serviceDetail"
        :options="options"
        :is-my-service="isMyService"
      />

      <!-- 非ログイン且つ電話サービス（占い）時の訴求 -->
      <div
        class="c-couponSignUpPhone"
        v-if="
          isPC &&
          !isLoggedIn &&
          serviceDetail.serviceDetail.serviceClass === serviceClass.phone &&
          parentCategoryId === categoryDivinationId
        "
      >
        {{ $t('components.service_detail_purchase.coupon') }}
      </div>

      <!-- Android 5.0未満、iOS Safari 11未満、Edge全て、IE全て、Safari 11未満 が対象 -->
      <div
        class="c-notVideoSupportedDevice"
        v-if="isPC && serviceDetail.serviceDetail.purchaseStatus === 'not_video_supported_device'"
      >
        {{ $t('components.service_detail_purchase.video_chat_message') }}
        <br />
        {{ $t('components.service_detail_purchase.environment') }}
        <!-- prettier-ignore -->
        <a
          href="/pages/guide_video"
          target="_blank"
        >{{ $t('here') }}</a>
      </div>

      <div
        v-if="isPC && isShownOptions && !isCustomizeRequired && !isMyService"
        class="c-options"
      >
        <div class="c-options_button c-optionsButton">
          <CoconalaIcon
            name="plus-circle"
            class="c-optionsButton_circle"
            size="17px"
          />
          <!-- prettier-ignore -->
          <span class="c-optionsButton_text">{{ $t('components.service_detail_purchase.charged_option') }}</span>
          <CoconalaIcon
            name="chevron-right"
            class="c-optionsButton_chevronRight"
            size="15px"
            v-if="!isActiveOptions"
          />
          <CoconalaIcon
            name="chevron-down"
            class="c-optionsButton_chevronDown"
            size="15px"
            v-if="isActiveOptions"
          />
        </div>
        <div
          class="c-options_optionList"
          v-if="isActiveOptions"
        >
          <!-- TODO: ServiceDetailContentsとServiceDetailPurchaseで同じ処理をしているのでまとめたい。ただデータの受け渡しが増えるので考えどころ -->
          <ul class="c-serviceOptionList">
            <li
              class="c-serviceOptionItem"
              :class="{ 'c-serviceOptionItem-active': isActiveOption(option.id) }"
              v-for="option in serviceDetail.serviceDetail.optionsList"
              :key="option.id"
            >
              <label class="c-serviceOptionItem_label">
                <div class="c-serviceOptionItem_name">
                  <CCheckbox
                    @input="updateOptions($event)"
                    :native-value="option.id"
                    v-model="innerOptions"
                    size="small"
                  >
                    <span class="c-serviceOptionItem_text">{{ option.title }}</span>
                  </CCheckbox>
                </div>
                <div class="c-serviceOptionItem_price">
                  <span class="c-serviceOptionItem_prefix">＋</span>
                  <!-- prettier-ignore -->
                  <span class="c-serviceOptionItem_pricewot">{{ $translate.multilingualCurrency(option.priceWot) }}</span>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="c-reservation"
      v-if="isPC && isServiceReservation"
    >
      <!-- prettier-ignore -->
      <span
        class="c-reservation_unregistered"
        @click="addServiceReservation($event)"
        v-if="!serviceDetail.buyer.isReservation && !setReservation"
      >{{ $t('components.service_detail_purchase.receive_notify') }}</span>
      <!-- prettier-ignore -->
      <span
        class="c-reservation_registered"
        v-else
      >{{ $t('components.service_detail_purchase.notify') }}</span>
    </div>

    <div
      v-if="isPC && isCustomize && !isMyService"
      class="c-customize"
      :class="{ 'c-customize-customizeRequired': isCustomizeRequired }"
    >
      <CButtonV2
        color="secondary"
        size="m"
        width="100%"
        :href="urlCustomize"
        need-logged-in
      >
        {{ $t('components.service_detail_purchase.consultation') }}
      </CButtonV2>
    </div>

    <div
      class="c-customizeRequired_text"
      v-if="isPC && isCustomizeRequired && !isMyService"
    >
      {{ $t('components.service_detail_purchase.consult_message') }}
      <br />
      {{ $t('components.service_detail_purchase.about_consult') }}
      <!-- prettier-ignore -->
      <a
        :href="$coconala.helpURL('/articles/360004882933')"
        target="_blank"
        rel="noopener"
      >{{ $t('here') }}</a>
    </div>

    <!-- ここからSP/TB用 -->
    <div
      class="c-serviceDetailPurchaseSp"
      v-if="isSP || isTB"
    >
      <div
        class="c-spTab"
        v-if="isSpTab"
      >
        <div
          v-if="!isCustomizeRequired"
          class="c-spTab_button"
          :class="{ 'c-spTab_button-active': !isActiveOptionsSp }"
        >
          <div
            class="c-spTabButtonReservation"
            v-if="isServiceReservation"
          >
            <!-- prettier-ignore -->
            <span
              class="c-spTabButtonReservation_unregistered"
              @click="addServiceReservation($event)"
              v-if="!serviceDetail.buyer.isReservation && !setReservation"
            >{{ $t('components.service_detail_purchase.short_receive_notify') }}</span>
            <!-- prettier-ignore -->
            <span
              class="c-spTabButtonReservation_registered"
              v-else
            >{{ $t('components.service_detail_purchase.notify') }}</span>
          </div>
          <div
            class="c-spTabButtonOption"
            @click="isActiveOptionsSp = true"
            v-else-if="isShownOptions"
          >
            <CoconalaIcon
              name="plus-circle"
              class="c-spTabButtonOption_circle"
              size="14px"
            />
            <span class="c-spTabButtonOption_text">
              {{ $t('components.service_introduction_modal.option') }}
            </span>
            <CoconalaIcon
              name="chevron-up"
              class="c-spTabButtonOption_chevronUp"
              size="10px"
            />
          </div>
        </div>
        <div
          class="c-spTab_list"
          :class="{ 'c-spTab_list-active': isActiveOptionsSp }"
          v-if="serviceDetail.serviceDetail.optionsList.length"
        >
          <div
            class="c-spTabOptionsSpTitle"
            @click="isActiveOptionsSp = false"
          >
            <div
              class="c-spTabOptionsSpTitle_text"
              v-if="isLoggedIn"
            >
              {{ $t('components.service_detail_purchase.charged_option') }}
            </div>
            <div
              class="c-spTabOptionsSpTitle_text"
              v-else
            ></div>
            <div class="c-spTabOptionsSpTitle_icon">
              <CoconalaIcon
                name="chevron-down"
                class="c-spTabOptionsSpTitle_chevronUp"
                size="10px"
              />
            </div>
          </div>
          <!-- TODO: ServiceDetailContentsとServiceDetailPurchaseで同じ処理をしているのでまとめたい。ただデータの受け渡しが増えるので考えどころ -->
          <ul class="c-spTabOptionsListSp">
            <li
              class="c-spTabOptionsItemSp"
              :class="{ 'c-spTabOptionsItemSp-active': isActiveOption(option.id) }"
              v-for="option in serviceDetail.serviceDetail.optionsList"
              :key="option.id"
            >
              <label class="c-spTabOptionsItemSp_label">
                <div class="c-spTabOptionsItemSp_name">
                  <!-- prettier-ignore -->
                  <CCheckbox
                    @input="updateOptions($event)"
                    :native-value="option.id"
                    :true-value="{ checked: true, value: option.id }"
                    :false-value="{ checked: false, value: option.id }"
                    v-model="innerOptions"
                    size="small"
                  ><span class="c-spTabOptionsItemSp_text">{{ option.title }}</span></CCheckbox>
                </div>
                <div class="c-spTabOptionsItemSp_price">
                  <span class="c-spTabOptionsItemSp_prefix">＋</span>
                  <!-- prettier-ignore -->
                  <span class="c-spTabOptionsItemSp_pricewot">{{ $translate.multilingualCurrency(option.priceWot) }}</span>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="c-spTabMain"
        :class="{
          'c-spTabMain-noTab': !isSpTab,
          'c-spTabMain-customizeRequired': isCustomizeRequired
        }"
      >
        <div
          class="c-spTabMainTalking"
          v-if="serviceDetail.serviceDetail.providerPhoneStatus === 'talking'"
        >
          {{ $t('components.service_detail_purchase.pending') }}
        </div>

        <div
          class="c-spTabMainCoupon"
          v-if="couponName"
          data-translate
        >
          {{ couponName }}
          <span
            class="c-spTabMainCoupon_expired"
            v-if="couponRemainingDays < 1"
          >
            ({{ $t('components.service_detail_purchase.until_today') }})
          </span>
          <span
            class="c-spTabMainCoupon_expired"
            v-else
          >
            ({{ $t('components.service_detail_purchase.remaining') }}
            <!-- prettier-ignore -->
            <span class="c-spTabMainCoupon_expiredTime">{{ couponRemainingDays }}</span>
            {{ $t('days') }})
          </span>
        </div>

        <!-- 非ログイン且つ電話サービス（占い）時の訴求 -->
        <div
          class="c-couponSignUpPhoneSp"
          v-if="
            !isLoggedIn &&
            serviceDetail.serviceDetail.serviceClass === serviceClass.phone &&
            parentCategoryId === categoryDivinationId
          "
        >
          {{ $t('components.service_detail_purchase.coupon') }}
        </div>

        <!-- Android 5.0未満、iOS Safari 11未満、Edge全て、IE全て、Safari 11未満 が対象 -->
        <div
          class="c-spTabMainNotVideoSupportedDevice"
          v-if="serviceDetail.serviceDetail.purchaseStatus === 'not_video_supported_device'"
        >
          {{ $t('components.service_detail_purchase.video_chat_message') }}
          <br />
          {{ $t('components.service_detail_purchase.environment') }}
          <!-- prettier-ignore -->
          <a
            href="/pages/guide_video"
            target="_blank"
          >{{ $t('here') }}</a>
        </div>

        <div
          class="c-spTabMainButtons"
          :class="{ 'c-spTabMainButtons-customizeRequired': isCustomizeRequired }"
          v-if="isLoggedIn"
        >
          <div
            class="c-spTabMainButtons_text"
            :class="{
              'c-spTabMainButtons_text-phone':
                serviceDetail.serviceDetail.serviceClass === serviceClass.phone,
              'c-spTabMainButtons_text-customizeRequired': isCustomizeRequired
            }"
          >
            <div class="c-spTabMainButtonsPrice">
              <span v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone">
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_price">{{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}</span>
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_unit">{{ $translate.exchangePriceUnit() }}/{{ $t('minute') }}</span>
                <!-- prettier-ignore -->
                <span
                  v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
                  class="c-spTabMainButtonsPrice_taxLabel"
                >({{ $t('excluding_tax') }})</span>
              </span>
              <span v-else-if="serviceDetail.serviceDetail.unitTime">
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_price">{{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}</span>
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_unit">{{ $translate.exchangePriceUnit() }}/{{ serviceDetail.serviceDetail.unitTime }}{{ $t('minute') }}</span>
                <!-- prettier-ignore -->
                <span
                  v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
                  class="c-spTabMainButtonsPrice_taxLabel"
                >({{ $t('excluding_tax') }})</span>
              </span>
              <span v-else>
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_price">{{ $util.numberWithDelimiter($translate.exchangePrice(totalPrice)) }}</span>
                <!-- prettier-ignore -->
                <span class="c-spTabMainButtonsPrice_unit">{{ $translate.exchangePriceUnit() }}</span>
                <!-- prettier-ignore -->
                <span
                  v-if="serviceDetail.serviceDetail.isRegisteredTaxOperator"
                  class="c-spTabMainButtonsPrice_taxLabel"
                >({{ $t('excluding_tax') }})</span>
              </span>
            </div>
            <div class="c-spTabMainButtonsPoint">
              <span v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone">
                {{ serviceDetail.pointRatio
                }}{{ $t('components.service_detail_purchase.gain_point') }}
              </span>
              <span v-else>
                {{ $util.numberWithDelimiter(totalPoint) }}{{ $t('point') }} ({{
                  serviceDetail.pointRatio
                }}％) {{ $t('components.service_detail_purchase.gain') }}
              </span>
            </div>
          </div>
          <div
            v-if="!isCustomizeRequired || isMyService"
            class="c-spTabMainButtons_button"
          >
            <ButtonPurchase
              button-size="m"
              :service-detail="serviceDetail"
              :options="options"
              :is-my-service="isMyService"
            />
          </div>
          <div
            v-else
            class="c-spTabMainButtons_button c-spTabMainButtons_button-customizeRequired"
          >
            <CButtonV2
              color="secondary"
              size="m"
              width="100%"
              :href="urlCustomize"
              need-logged-in
            >
              {{ $t('components.service_detail_purchase.consultation') }}
            </CButtonV2>
          </div>
        </div>
        <div
          class="c-spTabMainButtons c-spTabMainButtons-notLoggedIn"
          v-else
        >
          <!-- prettier-ignore -->
          <CButtonV2
            color="primary"
            size="m"
            width="100%"
            need-logged-in
          >{{ $t('components.service_detail_purchase.register') }}</CButtonV2>
        </div>

        <!-- 見積り必須文言 -->
        <div
          class="c-spTabMainCustomizeRequried"
          v-if="isLoggedIn && isCustomizeRequired"
        >
          {{ $t('components.service_detail_purchase.alert') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const SERVICE_ST_OPEN = 1

const DISCOUNT_TYPE = {
  AMOUNT: 1,
  RATIO: 2
}

// vuex
import { mapActions, mapGetters, mapState } from 'vuex'

// local components
import ButtonPurchase from './components/ButtonPurchase'

export default {
  name: 'ServiceDetailPurchase',
  components: {
    ButtonPurchase
  },
  props: {
    serviceDetail: {
      type: Object,
      required: true
    },
    parentCategoryId: {
      type: Number,
      default: null
    },
    totalPrice: {
      type: Number,
      required: true
    },
    urlCustomize: {
      type: String,
      required: true
    },
    isCustomize: {
      type: Boolean,
      required: true
    },
    isServiceReservation: {
      type: Boolean,
      required: true
    },
    setReservation: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    isMyService: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      innerOptions: this.options,
      isActiveOptions: false,
      isActiveOptionsSp: false,
      serviceStOpen: SERVICE_ST_OPEN
    }
  },
  watch: {
    options: function (array) {
      this.innerOptions = array
    },
    isActiveOptionsSp: function (val) {
      this.$util.hideZendeskWebWidgetWhileFlag(val)
    }
  },
  computed: {
    ...mapState('master', ['categoryDivinationId']),
    ...mapState('constants', ['serviceClass']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('auth', ['isLoggedIn']),
    totalPoint() {
      const pointRatio = this.serviceDetail.pointRatio
      return (this.totalPrice * pointRatio) / 100
    },
    couponName() {
      if (!this.serviceDetail.buyer.coupon.displayName) {
        return ''
      }
      if (this.serviceDetail.buyer.coupon.discountType === DISCOUNT_TYPE.AMOUNT) {
        if (this.$translate.isTranslatableTarget())
          return `${this.$t('components.service_detail_purchase.yen_discount')}`
        return `${this.serviceDetail.buyer.coupon.discount}${this.$t(
          'components.service_detail_purchase.yen_discount'
        )}`
      } else if (this.serviceDetail.buyer.coupon.discountType === DISCOUNT_TYPE.RATIO) {
        return `${this.serviceDetail.buyer.coupon.discount}%${this.$t(
          'components.service_detail_purchase.discount'
        )}`
      }
      return ''
    },
    couponRemainingDays() {
      const expireDate = this.serviceDetail.buyer.coupon.expireDate
      if (!expireDate) {
        return ''
      }
      const diff = this.$util.dayjs.unix(expireDate).diff(this.$util.dayjs())
      return Math.floor(this.$util.dayjs.duration(diff).asDays())
    },
    isSpTab() {
      if (this.isMyService) {
        return false
      }
      if (this.isServiceReservation) {
        return true
      }
      if (this.isLoggedIn && this.isShownOptions) {
        return true
      }
      return false
    },
    isCustomizeRequired() {
      return this.isCustomize && this.serviceDetail.serviceDetail.customizeRequiredFlag
    },
    isShownOptions() {
      return (
        this.serviceDetail.serviceDetail.optionsList.length &&
        ['available', 'can_advance_reservation', 'waiting_and_can_advance_reservation'].includes(
          this.serviceDetail.serviceDetail.purchaseStatus
        )
      )
    }
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    updateOptions(option) {
      this.$emit('changeOption', this.innerOptions)
    },
    isActiveOption(id) {
      return this.options.some(value => value === id)
    },
    async addServiceReservation(e) {
      if (!this.isLoggedIn) {
        e.preventDefault()
        this.showLoginModal()
        return
      }
      const serviceId = this.$store.state.route.params.id
      try {
        const serviceReservation = await this.$store.dispatch(
          'pages/services/serviceDetail/addServiceReservation',
          serviceId
        )
        if (this.$util.hasPath(serviceReservation, 'message') && serviceReservation.message) {
          this.$notify({
            group: 'flash',
            title: serviceReservation.message,
            type: 'success'
          })
        } else {
          this.$emit('emitReservation', true)
        }
      } catch (err) {
        this.$notify({
          group: 'flash',
          title: err.message,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-serviceDetailPurchase {
  margin-bottom: 24px;
  padding: 16px 24px;
  border: 1px solid $ds2-color-gray-50;
  border-radius: 4px;
}

.c-phoneTalking {
  margin-bottom: 8px;

  &_label {
    background: $ds2-color-brand-secondary-500;
    color: $color-white;
    text-align: center;
  }
}

.c-price {
  text-align: center;
  font-weight: bold;
  font-size: 14px;

  &_label {
    display: none;
  }

  &_price {
    font-size: 20px;
  }

  &_taxLabel {
    letter-spacing: 0.05em;
    font-size: 11px;
    line-height: 1.2;
  }
}

.c-point {
  margin: 4px 0 8px;
  color: $ds2-color-gray-600;
  text-align: center;
  font-size: 11px;
}

.c-coupon {
  margin: 4px 0;
  color: $ds2-color-orange-700;
  text-align: center;
  font-weight: bold;
  font-size: 12px;

  &_expiredTime {
    font-weight: bold;
  }
}

.c-couponSignUpPhone {
  margin: 4px 0;
  text-align: center;
  font-size: 11px;
}

.c-notVideoSupportedDevice {
  margin-top: 4px;
  text-align: center;
  font-size: 12px;
}

.c-options {
  position: relative;
  top: -2px;

  &_button {
    margin-top: 8px;
    padding-top: 2px;
    height: 22px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  &_optionList {
    position: absolute;
    top: 24px;
    left: -25px;
    z-index: 10;
    padding: 24px 0 0;
    width: calc(100% + 50px);
    border-right: 1px solid $ds2-color-gray-50;
    border-bottom: 1px solid $ds2-color-gray-50;
    border-left: 1px solid $ds2-color-gray-50;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background: $color-white;
    box-shadow: 0 3px 4px rgba(102, 102, 102, 0.3);
  }
}

.c-serviceOptionItem {
  &_label {
    display: block;
    padding: 16px 24px;
    border-top: 1px dotted $ds2-color-gray-100;
    cursor: pointer;
  }

  &_price {
    text-align: right;
    font-size: 15px;
    line-height: 1;
  }

  &_prefix {
    margin-right: 2px;
    color: $ds2-color-brand-secondary-700;
  }

  &-active {
    background: $ds2-color-brand-secondary-50;
    font-weight: bold;
    pointer-events: auto;
  }
}

.c-optionsButton {
  &_circle {
    margin-right: 4px;
    color: $ds2-color-brand-secondary-700;
  }

  &_text {
    margin-right: 4px;
  }

  &_chevronRight {
    color: $ds2-color-gray-100;
    vertical-align: middle;
  }

  &_chevronDown {
    color: $ds2-color-gray-100;
    vertical-align: middle;
  }
}

.c-reservation {
  padding: 4px 0;
  text-align: center;
  font-size: 12px;

  &_unregistered {
    @include link-effect;

    color: $ds2-color-brand-secondary-700;
    cursor: pointer;
  }
}

.c-customize {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $ds2-color-gray-50;

  ::v-deep .c-buttonV2 {
    &.is-m {
      padding: 0 4px;
    }
  }

  &-customizeRequired {
    margin-top: 12px;
    padding-top: 0;
    border-top: 0;
  }
}

.c-customizeRequired {
  &_text {
    margin: 12px 0 8px;
    width: 254px;
    font-size: 12px;
    line-height: 1.5;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-serviceDetailPurchase {
    margin-bottom: 0;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  .c-price {
    margin-top: 4px;
    padding: 0 12px;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;

    &-spLoggedIn {
      display: none;
    }

    &_label {
      display: inline-block;
      margin-right: 4px;
      padding: 4px 8px 5px;
      background: $ds2-color-gray-25;
      font-size: 12px;
    }

    &_content {
      position: relative;
      top: 1px;
      display: inline-block;
    }

    &_price {
      font-weight: normal;
      font-size: 12px;
    }
  }

  /* stylelint-disable */
  @media all and (-ms-high-contrast: none) {
    .c-price {
      display: none;
    }
  }
  /* stylelint-enable */

  .c-serviceDetailPurchaseSp {
    position: fixed;
    bottom: 0;
    z-index: z(fixed-footer);
    width: 100%;
  }

  .c-spTab {
    position: relative;
    z-index: z(fixed-footer-sp, tab);
    border-bottom: 1px solid $ds2-color-gray-50;
    text-align: right;

    &_button {
      position: absolute;
      top: 1px;
      right: 0;
      z-index: 1;
      display: inline-block;
      padding: 8px 16px 4px 24px;
      font-size: 12px;
      opacity: 0;
      cursor: pointer;
      transition: all 0.3s;
      transform: translate(0, 0);
      pointer-events: none;

      &-active {
        opacity: 1;
        transition: all 0.3s;
        transform: translate(0, -100%);
        pointer-events: auto;
      }

      &::before {
        position: absolute;
        top: -3px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-top: 1px solid $ds2-color-gray-50;
        border-left: 1px solid $ds2-color-gray-50;
        border-radius: 8px 0 0 0;
        background: $color-white;
        content: '';
        transform: scaleY(1) perspective(0.5em) rotateX(1.5deg);
        transform-origin: bottom right;
      }
    }

    &_list {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: $color-white;
      opacity: 0;
      cursor: pointer;
      transition: all 0.3s;
      transform: translate(0, 0);
      pointer-events: none;

      &-active {
        opacity: 1;
        transition: all 0.3s;
        transform: translate(0, -100%);
        pointer-events: auto;
      }
    }
  }

  .c-spTabButtonReservation {
    padding: 4px 0;
    width: 132px;
    text-align: center;
    font-size: 12px;

    &_unregistered {
      @include link-effect;

      padding: 4px 0 4px 8px;
      color: $ds2-color-brand-secondary-700;
      cursor: pointer;
    }
  }

  .c-spTabButtonOption {
    color: $ds2-color-brand-secondary-700;

    &_circle {
      margin-right: 4px;
    }

    &_text {
      margin-right: 4px;
    }

    &_chevronUp {
      vertical-align: middle;
    }
  }

  .c-spTabOptionsSpTitle {
    display: flex;
    padding: 12px 16px;
    background: $ds2-color-brand-secondary-700;
    color: $color-white;
    font-size: 12px;

    justify-content: space-between;

    &_icon {
      text-align: right;
    }
  }

  .c-spTabOptionsListSp {
    overflow-y: scroll;
    max-height: 400px;
  }

  .c-spTabOptionsItemSp {
    &:not(:last-child) {
      border-bottom: 1px dotted $ds2-color-gray-50;
    }

    &_label {
      display: flex;
      padding: 12px 16px;
      line-height: 24px;
      cursor: pointer;

      align-items: center;
      justify-content: space-between;
    }

    &_name {
      margin: 0;
      padding-right: 12px;
      text-align: left;
    }

    &_text {
      font-size: 12px;
    }

    &_price {
      text-align: right;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1;
    }

    &_prefix {
      margin-right: 2px;
      color: $ds2-color-brand-secondary-700;
    }

    &-active {
      background: $ds2-color-brand-secondary-50;
      font-weight: bold;
    }
  }

  .c-spTabMain {
    z-index: z(fixed-footer-sp, main);
    background: $color-white;

    &-noTab {
      border-top: 1px solid $ds2-color-gray-50;
    }

    &-customizeRequired {
      padding-top: 8px;
      padding-bottom: 12px;
    }
  }

  .c-spTabMainTalking {
    padding: 2px 0 2px;
    width: 100%;
    background: $ds2-color-brand-secondary-500;
    color: $color-white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .c-spTabMainCoupon {
    padding: 4px 0 0;
    width: 100%;
    color: $color-orange-400;
    text-align: center;
    font-weight: bold;
    font-size: 12px;

    &_expiredTime {
      font-weight: bold;
    }

    &-customizeRequired {
      padding-top: 8px;
      padding-bottom: 12px;
    }
  }

  .c-spTabMainNotVideoSupportedDevice {
    padding-top: 8px;
    text-align: center;
  }

  .c-couponSignUpPhoneSp {
    padding: 4px 0 0;
    text-align: center;
    font-size: 12px;
  }

  .c-spTabMainCustomizeRequried {
    text-align: center;
    font-size: 12px;
  }

  .c-spTabMainButtons {
    position: relative;
    display: flex;
    padding: 0 12px 12px;
    min-height: 58px;

    justify-content: space-between;

    &-notLoggedIn {
      padding-top: 4px;
      padding-bottom: 18px;
    }

    &-customizeRequired {
      padding-bottom: 4px;
    }

    &_text {
      margin: 4px 0;
      padding-right: 12px;
      color: $ds2-color-gray-600;
      text-align: center;
      font-size: 10px;

      flex-basis: 50%;
    }

    &_price {
      color: $ds2-color-gray-900;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }

    &_button {
      display: flex;

      flex-basis: 50%;

      &-customizeRequired {
        ::v-deep .c-buttonV2 {
          align-self: center;
          &.is-m {
            display: flex;
            padding-right: 2px;
            padding-left: 2px;
            font-size: 12px;
            line-height: 1.3;

            align-items: center;
            justify-content: center;
          }
        }
      }

      ::v-deep .c-buttonPurchase {
        width: 100%;

        align-self: center;
      }
    }
  }

  .c-spTabMainButtonsPrice {
    &_price {
      font-weight: bold;
      font-size: 20px;
    }

    &_unit {
      font-size: 14px;
    }

    &_taxLabel {
      font-size: 10px;
    }
  }

  .c-spTabMainButtonsPoint {
    font-size: 10px;
  }
}

@media (max-width: breakpoint(ServiceDetail, S)) {
  .c-spTabMainButtons {
    &_text {
      &-customizeRequired {
        padding-right: 0;
        min-width: 132px;

        flex-basis: 40%;
      }
    }

    &_button {
      &-customizeRequired {
        flex-basis: 60%;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .c-spTabMainButtons {
    align-items: center;

    &_text {
      &-customizeRequired {
        min-width: auto;

        flex-basis: auto;
        flex-grow: 1;

        .c-spTabMainButtonsPrice {
          &_price {
            font-size: 14px;
          }
        }
      }
    }

    &_button {
      &-customizeRequired {
        min-width: 160px;

        flex-basis: auto;
        ::v-deep .c-buttonV2 {
          &.is-m {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .c-spTabMainButtons {
    &_text {
      flex-basis: 40%;
      .c-spTabMainButtonsPrice {
        &_price {
          font-size: 16px;
        }
      }
      &-customizeRequired {
        .c-spTabMainButtonsPrice {
          &_price {
            font-size: 10px;
          }

          &_unit {
            font-size: 10px;
          }
        }
      }
    }

    &_button {
      flex-basis: 60%;
      &-customizeRequired {
        ::v-deep .c-buttonV2 {
          &.is-m {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
